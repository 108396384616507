import 'bootstrap';
import 'select2';

import { initLocale } from '../../i18n';
import { container_icon, bulk_icon } from './icons';

export function createPopoverComponent({containers, bulk_commodities}) {
  // ============================================
  // =============== UI ==================
  const cargoTypeSelector = (bulkState) => {
    let isBulkActive = Boolean(bulkState.weight || bulkState.commodity);
    return `
      <div class="component-search__cargo-types-tabs">
        <ul>
          <li class="${isBulkActive ? "" : "active"}" id="containers-item">
            <a role="tab" data-toggle="tab" href="#containers">
              ${container_icon}
              <span class="notranslate" translate="no">
                <span class="d-none d-md-block">Containers</span>
                <span class="d-none d-sl-block">Containers</span>
              </span>
            </a>
          </li>

          <li class="${isBulkActive ? "active" : ""}" id="bulk-item">
            <a role="tab" data-toggle="tab" href="#bulk">
              ${bulk_icon}
              <span class="notranslate" translate="no">
                <span class="d-none d-md-block">Bulk</span>
                <span class="d-none d-sl-block">Bulk</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    `;
  };

  const containerPopoverSelectorOption = (index, item) => {
    let id = `check${index}`;
    return `
      <div class="container-popover-select-row">
        <span class="container-popover-select-span custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="${id}" ${item.checked ? 'checked' : ''}>
          <label class="custom-control-label" for="${id}">${item.text}</label>
        </span>

        <span class="container-popover-select-span-action ${item.checked ? '' : 'invisible'}">
          <button class="container-popover-minus" ${item.count <= 1 ? `disabled` : ''}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 11H11H7V13H11H13H17V11H13ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#9E9E9E"/>
            </svg>
          </button>
          <span class="count">${item.count}</span>
          <button class="container-popover-plus">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#9E9E9E"/>
            </svg>
          </button>
        </span>
      </div>
    `;
  };

  const mapJoin = (items, cb) => {
    return items.map(cb).join('');
  };

  const resetBulkState = (state) => {
    state.weight = 0
    state.commodity = null
  }

  const resetContainerState = (state) => {
    state.forEach((container) => {
      container.checked = false;
      container.count = 1;
    })
  }

  const popoverUi = (containersState, bulkState) => {
    let cargoType = cargoTypeSelector(bulkState);

    let containerItems = mapJoin(containersState, (item, index) => {
      return containerPopoverSelectorOption(index, item);
    });

    let isBulkActive = Boolean(bulkState.weight || bulkState.commodity);

    let containerBody = `
      <div class="tab-pane component-search__cargo-types-tabs-pane ${isBulkActive ? "" : "active"}" id="containers">
        ${containerItems}
      </div>
    `;

    let bulkBody = `
      <div class="tab-pane component-search__cargo-types-tabs-pane ${isBulkActive ? "active" : ""}" id="bulk">
        <div class="bulk-popover-body">
          <div class="bulk-weight__wrapper">
            <label class="bulk-weight__label" for="bulk-weight">Gross Weight, MT</label>
            <input min="500" max="200000" type="number" class="component-search__input-wrapper" id="bulk-weight" value="${bulkState.weight}">
          </div>
          <div class="bulk-commodity__wrapper">
            <select id="bulk-commodity"></select>
          </div>
        </div>
      </div>
    `;

    let cargoBody = `
      <div class="tab-content component-search__cargo-types-tabs-content">
        ${containerBody}
        ${bulkBody}
      </div>
    `;

    let doneButton = `
        <div class="popover-done-button-wrapper">
          <button class="btn btn-link">
            ${translation['done']}
          </button>
        </div>
    `;

    return `
      ${cargoType}
      ${cargoBody}
      ${doneButton}
    `;
  };
  // ============================================

  // ============================================ // ============================================

  // ============================================
  // ============== CREATION ====================

  function createPopoverContainer({ selector, containers }) {
    const selectPopover = $(`
      <div class="popover-body-wrapper">
      </div>
    `);

    let containersState = containers.map(container => {
      return {
        text: container,
        count: 1,
        checked: false,
      };
    });

    let bulkState = {
      weight: 0,
      commodity: null
    };

    const data = gon.search_params && gon.search_params['containers'];
    if (data && gon.is_bulk) {
      let [weight, commodity] = data.split(";");
      bulkState.weight = weight;
      bulkState.commodity = commodity;
    } else if (data) {
      let dataObj = {};
      for (let elem of JSON.parse(data)) {
        let splitted = elem.split(':');
        dataObj = {
          [splitted[0]]: splitted[1],
          ...dataObj,
        };
      }

      for (let state of containersState) {
        if (dataObj[state.text]) {
          state.count = parseInt(dataObj[state.text]);
          state.checked = true;
        }
      }
    }

    function popoverRender() {
      let ui = popoverUi(containersState, bulkState);
      selectPopover.html(ui);

      // Cargo type tab switching event
      selectPopover
        .find('.component-search__cargo-types-tabs li')
        .on('click', function () {
          $('.component-search__cargo-types-tabs li').removeClass('active');
          $('.component-search__cargo-types-tabs li a').removeClass('active');
          $(this).addClass('active');
        });

      // Checkbox event
      $.each(
        selectPopover.find('.custom-control-input'),
        function (index) {
          let input = $(this);
          input.on('change', function () {
            containersState[index].checked = !containersState[index].checked;
            resetBulkState(bulkState)
            popoverRender();
          });
        },
      );

      // Plus button events
      $.each(
        selectPopover.find('.container-popover-plus'),
        function (index) {
          let button = $(this);
          button.on('click', () => {
            containersState[index].count++;
            resetBulkState(bulkState)
            popoverRender();
          });
        },
      );
      // Minus button events
      $.each(
        selectPopover.find('.container-popover-minus'),
        function (index) {
          let button = $(this);
          button.on('click', () => {
            containersState[index].count--;
            resetBulkState(bulkState)
            popoverRender();
          });
        },
      );

      selectPopover.find("#bulk-weight").on('change', function(){
        bulkState = {
          ...bulkState,
          weight: $(this).val()
        }
        resetContainerState(containersState)
        popoverRender();
      })

      selectPopover.find("#bulk-commodity").select2({
        data: bulk_commodities,
        placeholder: 'Commodity Type',
        allowClear: true,
        width: '100%',
      })
        .on('change', function() {
          const reRender = $(this).val() && bulkState.commodity !== $(this).val()
          
          bulkState = {
            ...bulkState,
            commodity: $(this).val()
          }
          if(reRender) {
            resetContainerState(containersState)
            popoverRender();
          }
        })
        .val(bulk_commodities.filter((c) => c.toLowerCase() === bulkState.commodity?.toLowerCase())[0])
        .trigger('change')

      // onClick Done
      selectPopover
        .find('.popover-done-button-wrapper button')
        .on('click', () => popoverClickAction());
    }

    function popoverClickAction(render_with_data=false) {
      (render_with_data && gon.is_bulk) || (!render_with_data && $('#bulk').hasClass('active'))
        ? bulkClickAction()
        : containersClickAction();
    }

    function containersClickAction() {
      let activeState = containersState.filter(elem => elem.checked);

      if (activeState.length == 0) {
        $(selector).val('');
      } else if (activeState.length == 1) {
        $(selector).val(`${activeState[0].count} x ${activeState[0].text}`);
      } else {
        let sum = activeState.reduce(
          (acc, item) => acc + (item.checked ? item.count : 0),
          0,
        );
        $(selector).val(`${sum} Containers`);
      }

      $(selector).popover('hide');

      let containers = activeState.map(elem => `${elem.text}:${elem.count}`);
      
      $(`${selector}-hidden`).val(JSON.stringify(containers));
      $(".cargo-icon").css('display', containers.length ? 'none' : 'block');
      $(".cargo-icon-dark").css('display', containers.length ? 'block' : 'none');
      $(".bulk-icon-dark").css('display', 'none');
    }

    function bulkClickAction() {
      $(selector).val(`${bulkState.weight} tons; ${bulkState.commodity}`);
      $(selector).popover('hide');

      $(`${selector}-hidden`).val(`${bulkState.weight};${bulkState.commodity}`);
      $(".cargo-icon").css('display', 'none');
      $(".cargo-icon-dark").css('display', 'none');
      $(".bulk-icon-dark").css('display', 'block');
    }

    popoverRender();
    if (data) popoverClickAction(true);

    $(selector).popover({
      trigger: 'manual',
      content: selectPopover.get(0),
      placement: 'bottom',
      fallbackPlacement: ['bottom'],
      flip: 'bottom',
      html: true,
    }).on('click', function (e) {
      e.preventDefault();

      $(this).popover("show");

      $(".popover").css('margin-top', '20px')
      $(".popover").css('margin-left', '8px')
      $(".popover .arrow").css('display', "none")
    }).on('show.bs.popover', function() {
      $('html').on('mousedown', function (e) {
        var l = $(e.target);
        if ($('.popover-body').get(0) && l.get(0)) {
          let isInside = $.contains($('.popover-body').get(0), l.get(0));
          if (!isInside) {
            $(selector).popover('hide');
            popoverClickAction();
          }
        }
      });
    }).on('hide.bs.popover', function() {
      $('html').off('mousedown')
    });
  }

  const translation = initLocale($("body").data('locale'))

  createPopoverContainer({
    selector: '.cargo-select',
    containers: containers,
  });

  $('.cargo-arrow').on('click', function () {
    $('.cargo-select').trigger('click');
  })

  $('.cargo-icon').on('click', function () {
    $('.cargo-select').trigger('click');
  })
  // ============================================  
}